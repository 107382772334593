import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard } from './core/authentication/authentication.guard';


const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('../app/login/login.module').then((m) => m.LoginModule)
  },
  {
    path: 'magic-login',
    loadChildren: () => import('../app/magic-login/magic-login.module').then((m) => m.MagicLoginModule)
  },
  {
    path: 'eventos',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('../app/events/events.module').then((m) => m.EventsModule)
  },
  {
    path: 'evento/:id/tickets',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('../app/sales2/sales.module').then((m) => m.SalesModule)
  },
  {
    path: 'evento/:id/generate',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('../app/generate/generate.module').then((m) => m.GenerateModule)
  },
  {
    path: 'evento/:id/impressions',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('../app/impressions/impressions.module').then((m) => m.ImpressionsModule)
  },
  {
    path: 're-impression/:impressionId',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('../app/re-impression/re-impression.module').then((m) => m.ReImpressionModule)
  },
  {
    path: 'evento/:id/sales',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('../app/sales2/sales-routing.module').then((m) => m.SalesRoutingModule)
  },
  {
    path: 'wolipay',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('../app/qr-simple/sales.module').then((m) => m.SalesModule)
  },
  {
    path: '',
    redirectTo: '/eventos',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
