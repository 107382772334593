import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as moment from 'moment';

@Injectable()
export class CheckoutService {

  constructor(private http: HttpClient) { }

  generateQR(orderId, event, total): Observable<any>  {
    const fechaVencimiento = moment(new Date()).add(1, 'day').format('DD/MM/yyyy');
    const bodyQr = {
      alias: orderId,
      callback: 'https://us-central1-clicket-bo.cloudfunctions.net/sipNotificationPos',
      detalleGlosa: event.name,
      monto: total,
      moneda: 'BOB',
      fechaVencimiento,
      tipoSolicitud: 'API'
    };
    return this.http.post('https://us-central1-clicket-bo.cloudfunctions.net/generateSipQR', bodyQr, {});
  }
}
